@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

@tailwind base;

body {
  margin: 0;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DMSans Regular';
  font-weight: normal;
  src: url('assets/fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'DMSans Medium';
  font-weight: normal;
  src: url('assets/fonts/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'DMSans Bold';
  font-weight: bold;
  src: url('assets/fonts/DMSans-Bold.ttf');
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@tailwind components;
@tailwind utilities;
